import React, { createContext, useContext, useState } from 'react';
import { useRecorder } from 'react-microphone-recorder';


interface RecorderContextProps {
    startRecording?: () => void;
    stopRecording?: () => void;
    pauseRecording?: () => void;
    resumeRecording?: () => void;
    resetRecording?: () => void;
    isRecording?: boolean;
    recordingState?: string;
    audioBlob?: Blob | null;
    audioFile?: File | null;
    audioURL?: string | null;
    audioLevel?: number | 0;
    timeElapsed?: number | 0;
}

interface AppContextProps {
    recorderContextProps: RecorderContextProps;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const {
        startRecording,
        stopRecording,
        pauseRecording,
        resumeRecording,
        resetRecording,
        isRecording,
        recordingState,
        audioBlob,
        audioURL,
        audioLevel,
        timeElapsed,
        audioFile
    } = useRecorder();

    const recorderContextProps: RecorderContextProps = {
        startRecording,
        stopRecording,
        pauseRecording,
        resumeRecording,
        resetRecording,
        isRecording,
        recordingState,
        audioBlob,
        audioURL,
        audioLevel,
        timeElapsed,
        audioFile
    };

    return (
        <AppContext.Provider value={{ recorderContextProps }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = (): AppContextProps => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};
