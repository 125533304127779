import React, { useState } from 'react';
import stylesPronto from '../../../Public/Pronto360.module.css';
import { Card, Button, Modal, Form, FormGroup, Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import UtilService from '../../../services/UtilService';
import TitleHeader from '../../../Public/Components/TitleHeader/TitleHeader';
import SingleInfoDescription from '../../Components/SingleInfoDescription/SingleInfoDescription';
import UtilServiceReact from '../../../services/UtilServiceReact';
import IntlTelInput from 'react-intl-tel-input';
import FloatingButton from '../../Components/FloatingButton/FloatingButton';
import UsersService from '../../Dashboard/Users/Users.service';
import './customStyle.css';
import type { FullAddressInterface } from '../../../interfaces/FullAddressInterface';

export const userGroups = [
  {
    ref: 'admin',
    title: "Admin",
    description: "Full control of platform features",
    permissions: '*'
  },
  {
    ref: 'manager',
    title: "Manager",
    description: "Create, view service orders and others limited actions",
    permissions: [
      { title: 'View totals', ref: 'view_totals' },
      { title: 'Create service order', ref: 'create_service_order' },
      { title: 'View service order', ref: 'view_service_order' }
    ]
  },
  {
    ref: 'sales-rep',
    title: "Sales Representative",
    description: "Create, view service orders and others limited actions",
    permissions: [
      { title: 'Create service order', ref: 'create_service_order' },
      { title: 'View service order', ref: 'view_service_order' }
    ]
  },
  {
    ref: 'employee',
    title: "Employee",
    description: "Access to simple platform features",
    permissions: [
      { title: 'View service order', ref: 'view_service_order' }
    ]
  },
]

interface User {
  userId: string;
  name: string;
  email: string;
  phone: string;
  address: string;
  fullAddress: FullAddressInterface;
  providerId: string;
  userType: string;
  groupPermission: string;
}
interface UsersAndPermissionsProps {
  list: any[];
  type: string;
  callback: (isUpdate: boolean) => void;
}
const UsersAndPermissionsList: React.FC<UsersAndPermissionsProps> = ({ list, type, callback }) => {
  const utilServiceReact = UtilServiceReact();
  const { saveUser, editUser, removeUser } = UsersService();
  const loggedUser: any = UtilService.getLocalUserData();
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentItem, setCurrentItem]: any = useState(null);
  const fullAddress: FullAddressInterface = {
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    addressDetails: "",
    currentLocation: null
  };
  const initFormData = {
    userId: "",
    name: "",
    email: "",
    address: "NOT_SET",
    fullAddress: fullAddress,
    phone: "",
    providerId: loggedUser && UtilService.isAdminOrEmployee() ? (UtilService.getAccountProviderId()) : null,
    userType: 'EMPLOYEE',
    groupPermission: ""
  }
  const [formData, setFormData] = useState<User>(initFormData);
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (event: any) => {
    const { name, value, type, checked } = event.target;
    let newValue: any;
    switch (type) {
      case "checkbox":
        newValue = checked;
        break;
      case "email":
        newValue = value.toLowerCase();
        break;
      default:
        newValue = value;
        break;
    }
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  const handlePhoneChange = (status: any, phoneNumber: any, country: any) => {
    const newPhone = phoneNumber;
    if (newPhone.replace(/\D/g, '').length < 11) {
      setPhoneNumber(phoneNumber);
    }
  };

  const onSelectAddress = (data: { address: string; fullAddress: FullAddressInterface }) => {
    if (data?.fullAddress) {
      setFormData({
        ...formData,
        address: data?.address,
        fullAddress: data?.fullAddress
      });
    }
  }

  const handleEditUser = (item: any) => {
    const postDataUser = {
      id: (item?._id ?? item?.id) ?? null,
      fullName: `${formData.name}`,
      email: `${String(formData.email).toLowerCase().trim()}`,
      phone: `${phoneNumber}`,
      ref: ``,
      providerId: `${formData.providerId}`,
      groupPermission: `${formData.groupPermission}`,
      address: `NOT_SET`,
      fullAddress: formData.fullAddress,
      userType: `EMPLOYEE`
    };

    utilServiceReact.showWarningAlert('Confirmation', 'Do you want to save?')
      .then(async res => {
        if (res.isConfirmed) {
          const response = postDataUser?.id ? await editUser(postDataUser) : await saveUser(postDataUser);
          if (response) {
            setShowEditModal(false);
            callback(true);
          }
        }
      })
  }

  const handleSetAsPermissionGroup = (event: any) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      groupPermission: prevFormData.groupPermission === event.ref ? '' : event.ref,
    }));
  };

  const handleRemoveUser = (item: any) => {
    try {
      utilServiceReact.showWarningAlert('Are you sure?', 'This action will remove the user permanently!').then(async (res) => {
        if (res.isConfirmed) {
          await removeUser(item?._id);
          setShowEditModal(false);
          callback(true);
        }
      });
    } catch (error) {
      console.error('Error transcribing:', error);
    }
  }

  const handleAddOpenModal = () => {
    setCurrentItem(null);
    setPhoneNumber("");
    setFormData(initFormData);
    setShowEditModal(true);
  }

  const handleEdit = (currentItem: any) => {
    setPhoneNumber(currentItem?.phone)
    setFormData({
      userId: (currentItem?.id || currentItem?._id) ?? "",
      name: currentItem?.fullName ?? "",
      email: currentItem?.email ?? "",
      phone: currentItem?.phone ?? "",
      address: currentItem?.address ?? "",
      fullAddress: currentItem?.fullAddress ?? "",
      providerId: loggedUser && UtilService.isAdminOrEmployee() ? (UtilService.getAccountProviderId()) : null,
      userType: 'EMPLOYEE',
      groupPermission: currentItem?.groupPermission ?? ""
    });
    setCurrentItem(currentItem);
    setShowEditModal(true);
  }

  return (
    <div className={`w-100 d-flex flex-column`}>
      <div className="flex-grow-1">
        <div className="main-content-app flex-grow-1">
          <div className="main-content-left main-content-left-chat overflow-hidden">
            <div className={`main-chat-list ${stylesPronto.scrollableList}`} id="UserList">
              <div className={stylesPronto.scrollableContent}>
                {list?.map((item: any, index) => (
                  <div key={index} className={`media ${item?.viewedProvider ? 'selected' : 'new'}`} onClick={() =>
                    handleEdit(item)}>
                    <div className={`${stylesPronto.mediaBody}`}>
                      <div className="media-contact-name">
                        <span>{UtilService.normalizeUrlToString(item?.fullName)}</span>{" "}
                        <span>{UtilService.getTimePassed(item?.createdAt)}</span>
                      </div>
                      <SingleInfoDescription icon={'fe fe-mail'} description={item?.email} textTransform={'lowercase'} />
                      <SingleInfoDescription icon={'fa fa-arrow-circle-right'} description={item?.groupPermission} textTransform={'uppercase'} />
                    </div>
                  </div>
                ))}
                {list.length === 0 && (
                  <>
                    <div className="m-3">
                      <p className="text-center m-3"><small>Empty list. Create new employee.</small></p>
                    </div>
                  </>
                )}
                {list.length < 5 && (
                  <Card.Footer>
                    <div className="shopping-cart-footer bd-0">
                      <div className="column">
                        <Link className="btn btn-outline-pronto" onClick={() => handleAddOpenModal()} to={'#'}>
                          New employee
                        </Link>
                      </div>
                    </div>
                  </Card.Footer>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatingButton icon={'bi-plus-lg'} callback={() => handleAddOpenModal()} />
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} fullscreen={true} dialogClassName="custom-modal-dialog">
        <TitleHeader
          title={'EMPLOYEE INFO'}
          subtitle={"ADD NEW EMPLOYEE"}
          description={"Fill the fields below to add or edit the employee info"}
          showGoBackBtn={true}
          forceTarget={'/admin/settings/users-and-permissions'}
        />
        <Modal.Body>
          <Col className={`${stylesPronto.externalContainer} p-1`} lg={12} xl={12}>
            <Form>
              {currentItem?.createdAt && (
                <SingleInfoDescription icon={'fa fa-calendar'} description={'<b>Created at: </b>' + UtilService.formatDateTime(currentItem?.createdAt)} textTransform={'none'} />
              )}
              <div className="clearfix my-2"></div>
              <section className="col-12">
                <FormGroup className="control-group form-group">
                  <Form.Label className="form-label">Name</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control"
                    required
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Name"
                  />
                  {(
                    <div className="invalid-field">
                      {utilServiceReact.requiredTextValidations(formData.name)?.msg}
                    </div>
                  )}
                </FormGroup>
                <FormGroup className="control-group form-group">
                  <Form.Label className="form-label">Email</Form.Label>
                  <Form.Control
                    type="email"
                    className="form-control"
                    required
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email Address"
                  />
                  {(
                    <div className="invalid-field">
                      {utilServiceReact.emailValidations(formData.email)?.msg}
                    </div>
                  )}
                </FormGroup>
                <FormGroup className="control-group form-group">
                  <Form.Label className="form-label">Phone</Form.Label>
                  <IntlTelInput
                    containerClassName="intl-tel-input mb-sm-0 w-100"
                    inputClassName="form-control"
                    defaultCountry="us"
                    format={true}
                    onPhoneNumberChange={handlePhoneChange}
                    value={phoneNumber}
                  />
                  {(
                    <div className="invalid-field">
                      {utilServiceReact.phoneValidations(phoneNumber)?.msg}
                    </div>
                  )}
                </FormGroup>
                {userGroups?.map((item: any, index) => (
                  <Card key={index} className={formData.groupPermission === item.ref ? 'bg-highlight' : ''}>
                    <Card.Body>
                      <Row>
                        <Col lg={12}>
                          <div className="d-flex">
                            <div>
                              <p className="tx-20 font-weight-semibold d-flex mb-0">
                                {item.title}
                              </p>
                              <p className="tx-13 text-muted mb-0" dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                              <Form.Label className="text-left mb-0">
                                <Link to="#" className="tx-14 mb-0 me-2">
                                  Set as {item.title}
                                </Link>
                                <input
                                  type="checkbox"
                                  name="custom-switch-checkbox3"
                                  className="custom-switch-input"
                                  checked={formData.groupPermission == item.ref}
                                  onChange={() => handleSetAsPermissionGroup(item)}
                                />
                                <span className="custom-switch-indicator custom-radius"></span>
                              </Form.Label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ))}
              </section>
            </Form>
          </Col>
        </Modal.Body>
        <Modal.Footer>
          <div
            aria-label="Action buttons"
            className="btn-group w-100"
            role="group"
          >
            <Button
              variant=""
              className="btn btn-outline-pronto"
              type="button"
              onClick={() => setShowEditModal(false)}
            >
              Close
            </Button>{" "}
            <Button
              variant=""
              className="btn btn-outline-pronto"
              type="button"
              onClick={() => handleRemoveUser(currentItem)}
              disabled={currentItem == null}
            >
              Delete
            </Button>{" "}
            <Button
              variant=""
              className="btn btn-outline-pronto"
              type="button"
              onClick={() => handleEditUser(currentItem)}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UsersAndPermissionsList;
