import React, { useEffect } from "react";
import { Breadcrumb, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { ADD, Delete } from '../redux/actions/action';
import ProductService from "../services/ProductService";
import Header from "./Components/Header/Header";
import styles from "./Pronto360.module.css";
import SubCategoriesList from "./Components/SubCategoriesList/SubCategoriesList";
import Search from "./Components/Search/Search";

const Pronto360 = () => {
  const [list, setList] = React.useState<any>({
    categoriesList: ProductService.getCategories(),
  });

  const navigate = useNavigate();

  /*
    useEffect(() => {
      navigate(`${process.env.PUBLIC_URL}/admin/service-order`);
    }, []);
  */
  
  function handleRemove(id: any) {
    const newList = list.filter((list: any) => list.id !== id);
    setList(newList);
  }
  const dispatch = useDispatch();
  const send = (e: any) => {
    // console.log(e);
    dispatch(ADD(e));
  }
  const ondelete = (item: any) => {
    dispatch(Delete(item))
  }

  let { categoriesList } = list;

  return (
    <div>
      <Header />
      {/* 
      <Col className={`${styles.externalContainer} p-1`} lg={12} xl={12}>
        <Search categoriesList={categoriesList} />
        {categoriesList.map((item: any, index: number) => (
          <SubCategoriesList key={index} titleCategory={item.titleCategory} subCategoriesList={item.subCategoriesList} />)
        )}
      </Col>
        */}
      {/* <!-- COL-END --> */}
    </div>
  );
}


Pronto360.propTypes = {};

Pronto360.defaultProps = {};

export default Pronto360;
